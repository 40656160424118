<!--
 * @Author: jiang
 * @Date: 2021-07-12 11:40:28
 * @Description: 人员选择 可以通过部门id筛选
-->
<template>
  <el-select
    :value="value"
    :multiple='multiple'
    filterable
    clearable
    reserve-keyword
    @change="onChange"
  >
    <el-option
      v-for="item in userList"
      :key="item.id"
      :value="item.id"
      :label="handlerLabel(item)"
    ></el-option>
  </el-select>
</template>
<script>
import { getUserList } from '@/api/user'

export default {
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    value: [String, Number, Array],
    deptId: {
      type: [String, Number],
    },
  },
  data() {
    return {
      userList: [],
    }
  },
  watch: {
    deptId: {
      handler() {
        this.fetchUserList()
      },
      immediate: true,
    },
  },
  methods: {
    fetchUserList() {
      getUserList({
        department_id: this.deptId,
      }).then(res => {
        this.userList = res
      })
    },
    onChange(value) {
      this.$emit('input', value)
      this.$emit('change', value)
    },
    handlerLabel(item) {
      if (this.deptId) {
        return item.name
      } else {
        return item.department?.name + '-' + item.name
      }
    },
  },
}
</script>
